import React from 'react'
import Footer from '../Partials/Footer'
import Header from '../Partials/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import TestimonialScroll from './testimonial'

import Modal from 'react-bootstrap/Modal';

import { saveAs } from 'file-saver';
import { Container } from 'react-bootstrap';


function MyVerticallyCenteredModal(props) {
  const certificate="./images/certificate.png";

  const downloadImage=()=> {
    saveAs(certificate, 'certificate.png')
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <FontAwesomeIcon icon={faDownload} onClick={downloadImage} />
      </Modal.Header>
      <Modal.Body>
        <img src="./images/certificate.png" alt="" />
      </Modal.Body>
    </Modal>
  );
}

export default function Product() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="product--topbg"></div>
        <Header />
        <section className="product--top">
          <div className="container">
            <div className="product--container">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className='prdoduct--details'>
                    <div className="product__header">
                      <h1>Install Solar projects quicker & better with Surya</h1>
                    </div>
                    <div className="product__subheading mt-5">
                      <h4>Custom made B2B digital platform exclusively catering to utility scale solar industry players</h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-end">
                  <div className="product__img">
                    <img src="./images/product1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


      <section className="surya">
        <div className="container">            
              {/* <div className="surya__logo">
                <img src="./images/suryalogo.png" alt="" height={52} width={135} />
              </div>
              <div className="surya__paragraph">
                <p>Our launch version v1.0 enables customers working on projects with Nextracker Technology to</p>
              </div> */}

              <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="surya--animation">
                <div className="surya--box" data-aos="slide-up"
                                            data-aos-offset="3"
                                            data-aos-delay="20"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out">
                  <div className="surya--topic">
                    <h3>Visualize</h3>
                  </div>
                  <div className="surya__list d-flex align-items-center mt-4">
                    <div className="success--logo">
                      <FontAwesomeIcon icon={faCircleCheck} style={{width:"90px",height:"90px"}} />
                    </div>
                    <div className="success--paragraph">
                      <p>Convert all your project components into digital elements that you can interact. Record and view progress of any construction activity across the site at overall project level or individual block level with color coded tracker & table components.</p>
                    </div>
                  </div>
                </div>

                <div className="surya--box" data-aos="slide-up"
                                            data-aos-offset="3"
                                            data-aos-delay="20"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out">
                  <div className="surya--topic">
                    <h3>Plan</h3>
                  </div>
                  <div className="surya__list d-flex align-items-center mt-4">
                    <div className="success--logo">
                      <FontAwesomeIcon icon={faCircleCheck} style={{width:"90px",height:"90px"}} />
                    </div>
                    <div className="success--paragraph">
                      <p>View & analyze bill of material requirements & consumption for any portion of the site and through our integrated logistics management feature you can plan the locations of laydown, storage & inter-site movement of materials.</p>
                    </div>
                  </div>
                </div>

                <div className="surya--box" data-aos="slide-up"
                                            data-aos-offset="3"
                                            data-aos-delay="20"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out">
                  <div className="surya--topic">
                    <h3>Assure</h3>
                  </div>
                  <div className="surya__list d-flex align-items-center mt-4">
                    <div className="success--logo">
                      <FontAwesomeIcon icon={faCircleCheck} style={{width:"90px",height:"90px"}} />
                    </div>
                    <div className="success--paragraph">
                      <p>Use the only cutting edge software in the market that allows you to click on tracker & table components, pull up corresponding checklists, take photos, add punch list items, track resolutions, generate quality documentations and more.</p>
                    </div>
                  </div>
                </div>
                
                {/* <div className="surya--box" data-aos="slide-up"
                                            data-aos-offset="20"
                                            data-aos-delay="50"
                                            data-aos-duration="1000"
                                            data-aos-easing="ease-in-out">
                  <div className="surya--topic">
                    <h3>Manage</h3>
                  </div>
                  <div className="surya__list d-flex align-items-center mt-4">
                    <div className="success--logo">
                      <FontAwesomeIcon icon={faCircleCheck}/>
                    </div>
                    <div className="success--paragraph">
                      <p>Add details of site staff, manage and track work hours by activity</p>
                    </div>
                  </div>
                </div> */}

              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center ">
              <div className="surya--img">
                <img src="./images/about2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='why--surya'>
        <div className="container">
          <div className="surya--header">
            <h2>Why use Surya</h2>
          </div>
          <div className="surya--paragraph mt-4">
            <p>Simple, imagine a software which is custom-built, not just for your industry but for your specific project. That’s what we offer, contact us for a demo</p>
          </div>
        </div>
      </section> */}

      <div className="container"> <div>
        <div className="about__header">
          <h2>Client Testimonials</h2>
        </div>
        <div className="about__paragraph ml-20">
          <p style={{padding:"0%", fontweiht:"10"}}>
            Hear what our clients have to say about working with us
          </p>
        </div>
      </div>
      {/* Testimonials Container */}
      <TestimonialScroll/></div>
      {/* <section className='member'>
        <div className="container">
          <div className="member--header">
            <h2>Why become a member of Surya</h2>
          </div>
          <div className="row mt-15">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="member-box">
                <div className="member--icon">
                  <img src="./images/data.png" alt="" />
                </div>
                <div className="member--header">
                  <h3>Data Valuation Platform</h3>
                </div>
                <div className="member--paragraph mt-3">
                  <p>Apart from using the services on our platform, Surya will be the first ever data valuation platform operating in a large scale B2B environment.</p>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="member-box">
                <div className="member--icon">
                  <img src="./images/benefit.png" alt="" />
                </div>
                <div className="member--header">
                  <h3>Derive Monetary Benefits</h3>
                </div>
                <div className="member--paragraph mt-3">
                  <p>What’s more, companies could also derive direct monetary benefits by sharing their project specific data on our platform.</p>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="member-box">
                <div className="member--icon">
                  <img src="./images/reaily.png" alt="" />
                </div>
                <div className="member--header">
                  <h3>Readily Available Data for Use</h3>
                </div>
                <div className="member--paragraph mt-3">
                  <p>Companies working on a project would be able to reap maximum benefits of having non-confidential project specific data readily available for use.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section> */}

      <section>  
       <div className="container paragraph text-xl-center " >
          <p style={{"font-weight":"500pt"}}>We use and manage your personal information in accordance with our (<a href='/Rishi Tech Pty Ltd - Privacy Policy (LV 23 Nov 2023).pdf'>Privacy policy</a>).</p>
        </div>
      </section>

      {/* <section className="data">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="data--header">
                <h2>Data security</h2>
              </div>
              <div className="data--paragraph mt-5">
                <p>Your data security is our highest priority. Our company and the platform SURYA are assessed and conform to the requirements of <span onClick={() => setModalShow(true)}>ISO 27001:2013</span> (Information security management system).
                  <br />
                  <br />
                  Yes, we are a startup but data security and compliance is where we spent first. We shunned the idea of building an MVP (Minimum viable product) or rather we interpreted it as Maximum Viable Product to offer you a fully functional & complete product with the necessary security systems in place.
                  <br />
                  <br />
                  That’s not all, we have plans to become SOC 2 compliant in 2023. Rest assured, the product will have the highest data security standards in line and most likely better than industry standards.
                </p>
                <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              </div>
              
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-center">
              <div className="data--img">
                <img src="./images/security.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='certificate' id='certificate'>
        <div className="container">
          <div className="certificate--box" onClick={() => setModalShow(true)}>
            <div className="certificate--container">
              <div className="certificate__img">
                <img src="./images/iso.png" alt="" height={119} width={119} />
              </div>
              <div className="certificate__details">
                <h5>Certificate Number</h5>
                <h3>BQSR2042</h3>
              </div>
            </div>
            <div className="certificate__arrow">
              <img src="./images/arrow.png" alt="" />
            </div>
          </div>
          <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
        </div>
      </section> */}

      <section className="hero">
        <div className="container">
          <div className="hero__img">
            <img src="./images/heroicon.png" alt="" height={80} width={80} />
          </div>
          <div className="hero__header mt-4">
            <h2>What does <br /> the future look like</h2>
          </div>
          <div className="hero__paragrapgh mt-4">
            <p>The next big feature slated for release is a tightly integrated Staff & Plant management that leverages the platform to provide customized services for contractors on a project by project basis. We are just getting started and there are much more features in the works.</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);
