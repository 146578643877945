import React, { useState, useEffect, useRef } from "react";
//  testimonial data
const testimonials = [
  {
    id: 1,
    name: "Andrew Senn",
    role: "National Manager - Future Energy",
    company: "Northpower Ltd., New Zealand",
    image: "/images/north_power_logo.jpeg?height=30&width=30",
    quote: "The team at SURYA delivered value at every stage of the project, from highlighting bill of material errors at the earliest stage through to flexibility in configuration to allow a variety of reporting the functionality and flexibility of SURYA is impressive, this is a tool we would carry into any project."
  },

  {
    id: 2,
    name: "Dan Thornett",
    role: "Construction Supervisor",
    company: "Bam Bam Pile Driving",
    image: "/images/bam_bam_logo.jpeg?height=30&width=30",
    quote: "SURYA is a game changer when building utility scale solar farms. It makes everything from logistics tracking and planning through to Quality and overall construction tracking so much easier. The team at SURYA are extremely accommodating with the project needs and are quick to help with providing app training and evolving the app to suit the needs for building the site."
  },
  {
    id:3,
    name:"Micaela Tofay",
    role:"HR & Logistics Manager",
    company:"Solmech",
    image:"/images/solmech_logo.png?height=30&width=60",
    quote:"SURYA has been a key tool in tracking the progress of our project at Gunsynd Solar Farm. Its intuitive and adaptable interface has allowed us to record real-time production updates while clearly distinguishing the work of each team. Additionally, its ability to divide tasks, manage multiple processes simultaneously, and classify activities as 'Not Started,' 'In Progress,' or 'Completed' has greatly improved our planning. It also facilitates on-site material stock management, ensuring better logistical control. Without a doubt, due to its flexibility and efficiency, I would recommend it for any project, regardless of its size or complexity."
  }, 
  {
    id: 4,
    name: "Tanya Zdebliak",
    role: "Construction supervisor ",
    company: "Northpower Ltd., New Zealand",
    image: "/images/north_power_logo.jpeg?height=30&width=30",
    quote: "Working with the SURYA App out in the field was so easy to use and made the project run exceptionally smooth. Easy to pass on information to the Contractors for mechanical build, Quality Control team for QC checks and reporting up to the Executive team. A true asset for this exciting project."
  },
]

export default function TestimonialScroll() {
  const [index, setIndex] = useState(0);
  const [intervalms, setIntervalms] = useState(3000);
  const [testimonialWidth, setTestimonialWidth] = useState(0);
  const carouselRef = useRef(null);
  const containerRef = useRef(null);

  // Measure testimonial width dynamically
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setTestimonialWidth(containerRef.current.offsetWidth * 0.80); // 80% width to reveal sides
      }
    };

    updateWidth(); // Set initial width
    window.addEventListener("resize", updateWidth); // Adjust on resize

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  // Auto-slide functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, intervalms);
    return () => clearInterval(interval);
  }, [intervalms]);

  // Move carousel based on dynamic width
  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.style.transition = "transform 0.5s ease-in-out";
      carouselRef.current.style.transform = `translateX(-${index * testimonialWidth}px)`;
    }
  }, [index, testimonialWidth]);

  const moveRight = () => {
    setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const moveLeft = () => {
    setIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div
      ref={containerRef}
      className="flex-container"
      onMouseOver={() => setIntervalms(90000)}
      onMouseLeave={() => setIntervalms(3000)}
      style={{
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        width: "90%",
        maxWidth: "1200px",
        marginBottom: "10%",
        paddingLeft: "10%"
      }}
    >
      {/* Left Button */}
      <button
        onClick={moveLeft}
        style={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          background: "rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          zIndex: 1,
          fontSize: "xx-large",
        }}
      >
        {"<"}
      </button>

      {/* Carousel */}
      <div
        ref={carouselRef}
        style={{
          display: "flex",
          transition: "transform 0.5s ease-in-out",
          width: "100%",
        }}
      >
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className="testimonial-container"
            onClick={()=>{setIndex(testimonial.id-1)}}
            style={{
              flex: "0 0 auto",
              width: `${testimonialWidth}px`,
              padding: "2%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="bg-white rounded-xl shadow-lg p-4 flex flex-col justify-between"
              style={{
                minHeight: "150px",
                height: "100%",
                maxWidth: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
              }}
            >
              {/* Quote Icon */}
              <svg
                style={{ width: "10%", height: "10%" }}
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
              </svg>

              {/* Quote Text */}
              <p className="testimonial-text">{testimonial.quote}</p>

              {/* Author Info */}
              <div
                className="flex items-center"
                style={{ paddingTop: "2%", marginTop: "2%",  display: "flex", flexDirection: "row",flexWrap: "wrap",gap:"20px"}}
              >
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  style={{
                    
                    maxWidth: "100px",
                    borderRadius: "15%",
                    marginRight: "10px",
                  }}
                />
                <div style={{alignSelf:"center"}}>
                  <b>{testimonial.name}</b>
                  <br />
                  {testimonial.role}
                  <br />
                  {testimonial.company}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Right Button */}
      <button
        onClick={moveRight}
        style={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          background: "rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          zIndex: 1,
          fontSize: "xx-large",
        }}
      >
        {">"}
      </button>
    </div>
  );
}